import React from 'react'

import styled from 'styled-components'
import Mark from './Mark'

const MarkDetails = ({
  text = '',
  isCorrect,
  onCheck,
  onUncheck,
  textPosition = 'right',
  exactBooleanCheck = false,
}) => {
  let component

  if (exactBooleanCheck) {
    if (typeof isCorrect !== 'boolean') {
      component = (
        <StyledMarkDetails>
          {textPosition === 'left' && <p>{text}</p>}
          <div className="mark-wrapper">
            <Mark disable={isCorrect} onClick={onCheck} type="correct" />
            <Mark disable={!isCorrect} onClick={onUncheck} />
          </div>
          {textPosition === 'right' && <p>{text}</p>}
        </StyledMarkDetails>
      )
    } else {
      component = (
        <StyledMarkDetails>
          {textPosition === 'left' && <p>{text}</p>}
          <div className="mark-wrapper">
            <Mark
              disable={isCorrect}
              onClick={onCheck}
              type="correct"
              isActive={isCorrect}
            />
            <Mark
              disable={!isCorrect}
              onClick={onUncheck}
              isActive={!isCorrect}
            />
          </div>
          {textPosition === 'right' && <p>{text}</p>}
        </StyledMarkDetails>
      )
    }
  } else {
    component = (
      <StyledMarkDetails>
        {textPosition === 'left' && <p>{text}</p>}
        <div className="mark-wrapper">
          <Mark
            disable={isCorrect}
            onClick={onCheck}
            type="correct"
            isActive={isCorrect}
          />
          <Mark
            disable={!isCorrect}
            onClick={onUncheck}
            isActive={!isCorrect}
          />
        </div>
        {textPosition === 'right' && <p>{text}</p>}
      </StyledMarkDetails>
    )
  }

  return component
}

export default MarkDetails

const StyledMarkDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .mark-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: 300;
    color: #1a1a1a;
  }
`
