import React, { useState, useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import Joi from 'joi'

import Flex from '../../../../components/layouts/Flex'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import DashboardInput from '../../../../components/dashboard/DashboardInput'

import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'

import useFetch from '../../../../hooks/useFetch'

import {
  insertRatingGroup,
  updateRatingGroup,
  setErrorMessage,
} from '../../../../store/actions/ratingActions'

const SaveRatingGroup = () => {
  const dispatch = useDispatch()

  const { loading, modal, selectedRecord } = useSelector(
    (state) => state.ratings
  )

  const { data: ratingTypes } = useFetch('/rating-types')

  const validationSchema = useMemo(() => {
    return Joi.object({
      name: Joi.string().label('Name').required(),
      rating_type_id: Joi.number()
        .label('Rating Type')
        .invalid(null)
        .required()
        .messages({
          'any.required': 'Rating Type is required.',
          'any.invalid': 'Rating Type is required.',
          'number.base': 'Please select a rating type.',
        }),
    })
  }, [])

  const [name, setName] = useState('')
  const [ratingTypeId, setRatingTypeId] = useState('')
  const [ratingTypeName, setRatingTypeName] = useState(null)
  const [fieldErrors, setFieldErrors] = useState([])

  const handleSelectRatingType = (e) => {
    const selectedIndex = e.target.selectedIndex
    const selectedOption = e.target.options[selectedIndex]
    const label = selectedOption.textContent
    setRatingTypeName(label)
    setRatingTypeId(e.target.value)
  }

  const handleSaveRatingGroup = () => {
    const payload = {
      name,
      rating_type_id: ratingTypeId,
    }

    const result = validationSchema.validate(payload, {
      abortEarly: false,
    })

    if (result.error) {
      const { error } = result
      const fieldNames = Object.keys(payload)
      const updatedErrors = fieldNames.reduce((acc, fieldName) => {
        acc[fieldName] =
          error?.details
            .find((detail) => detail.context.key === fieldName)
            ?.message.replace(/"/g, '') || ''
        return acc
      }, {})

      setFieldErrors(updatedErrors)

      dispatch(
        setErrorMessage(
          'Please correct the validation errors first before proceeding.'
        )
      )
      return
    }

    if (modal === 'updateRatingGroup') {
      dispatch(
        updateRatingGroup(selectedRecord.ratingGroup.id, payload, {
          rating_type_name: ratingTypeName,
        })
      )
    } else {
      dispatch(
        insertRatingGroup(payload, {
          rating_type_name: ratingTypeName,
        })
      )
    }
  }

  useEffect(() => {
    if (modal === 'updateRatingGroup') {
      setName(selectedRecord.ratingGroup.name)
      setRatingTypeId(selectedRecord.ratingGroup.rating_type_id)
      setRatingTypeName(selectedRecord.ratingGroup.rating_type_name)
    }
  }, [selectedRecord.ratingGroup, modal])

  return (
    <MainModal
      title={
        modal === 'updateRatingGroup'
          ? 'Update Rating Group'
          : 'Create Rating Group'
      }
      onClick={handleSaveRatingGroup}
      buttonText="Submit"
      loading={loading}
    >
      <Flex gap="0.4rem">
        <DashboardInput
          errorMessage={fieldErrors.name}
          name="name"
          label="Name *"
          placeholder="Enter Name"
          type="text"
          height="2.5rem"
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
      </Flex>
      <Flex direction="column" gap="0.4rem" margin="1rem 0 0 0">
        <SingleDropdownSelect
          onChange={handleSelectRatingType}
          title="Select Rating Type"
          errorMessage={fieldErrors.rating_type_id}
          value={ratingTypeId}
        >
          <option value={null}>Choose an option</option>
          {ratingTypes?.resp?.map((ratingType) => (
            <option key={ratingType?.id} value={ratingType?.id}>
              {ratingType?.name}
            </option>
          ))}
        </SingleDropdownSelect>
      </Flex>
    </MainModal>
  )
}

export default SaveRatingGroup
