/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'

import RichTextbox from '../../../../components/dashboard/controls/RichTextBox'
import DashboardInput from '../../../../components/dashboard/DashboardInput'
import { FilterCheckBox } from '../../../../components/dashboard/FilterInput'
import Modal from '../../../../components/dashboard/Modal'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'
import { JobToggleButton } from '../../../../components/dashboard/ToggleButton'
import Flex from '../../../../components/layouts/Flex'

import axiosInstance from '../../../../services/config/AxiosIntance'

import useFetch from '../../../../hooks/useFetch'

import { setRecruiterId } from '../../../../store/actions/jobPostingActions'

import Select from 'react-select'
import countriesList from 'all-countries-and-cities-json'
import styled from 'styled-components'

import {
  SET_ACTIVE_VACANCY,
  SET_COMPANY_ID,
  SET_POC_ID,
  SET_JOB_DESCRIPTION,
  SET_JOB_TITLE,
  SET_JOB_TYPE,
  SET_POLLING,
  SET_REQUIRES_COACHING,
  SET_TIME_SHIFT,
  SET_VACANCY_FOR_POOLING,
  SET_AVAILABLE_COUNTRIES,
  SET_JOB_RATING_GROUP,
  SET_INTERVIEW_RATING_GROUP,
} from '../../../../store/types/jobPosting'
import {
  SET_REVIEW_POST_MODAL,
  SHOW_MODAL,
  HIDE_MODAL,
} from '../../../../store/types/modal'

const JobDetailsModal = () => {
  const dispatch = useDispatch()

  const [initialState, setIntialState] = useState({
    jobTitle: '',
    jobType: '',
    polling: false,
    activeVacancy: '',
    vacancyForPooling: '',
    timeShift: '',
    description: '',
    client: '',
    requiresCoaching: false,
    countries: '',
  })

  const {
    polling,
    requires_coaching,
    description,
    title,
    job_type,
    active_vacancy,
    vacancy_for_pooling,
    time_shift,
    client,
    poc_id,
    company_id,
    recruiter_id,
    countries,
    job_rating_group_id,
    interview_rating_group_id,
  } = useSelector((state) => state.jobPosting)

  const { data: recruiters } = useFetch('/recruiters')

  const [companies, setCompanies] = useState([])
  const [contacts, setContacts] = useState([])
  const [allCountries, setCountries] = useState([])
  const [jobRatingGroups, setJobRatingGroups] = useState([])
  const [interviewRatingGroups, setInterviewRatingGroups] = useState([])

  // handle pooling
  const handlePooling = () => {
    dispatch({ type: SET_POLLING })
    dispatch({
      type: SET_VACANCY_FOR_POOLING,
      payload: 0,
    })
  }

  const handleDescription = (value) => {
    dispatch({ type: SET_JOB_DESCRIPTION, payload: value })
  }

  useMemo(() => {
    Object.keys(countriesList)?.forEach((country) => {
      setCountries((allCountries) => [
        ...allCountries,
        { value: country, label: country },
      ])
    })
    return allCountries
  }, [countriesList])

  useEffect(() => {
    setIntialState((prevState) => ({
      ...prevState,
      jobTitle: title,
      jobType: job_type,
      polling: polling,
      activeVacancy: active_vacancy,
      vacancyForPooling: vacancy_for_pooling,
      timeShift: time_shift,
      description: description,
      requiresCoaching: requires_coaching,
    }))
  }, [])

  useEffect(() => {
    axiosInstance.get('/company?client_status_id=1').then((response) => {
      setCompanies(response?.data?.data)
    })
    axiosInstance.get('/rating-groups?ratingTypeNames=1').then((response) => {
      const jobRatings = response?.data?.resp?.filter(
        (rating) => rating.rating_type_name === 'Job Rating'
      )
      const interviewRatings = response?.data?.resp?.filter(
        (rating) => rating.rating_type_name === 'Interview Rating'
      )
      setJobRatingGroups(jobRatings)
      setInterviewRatingGroups(interviewRatings)
    })
  }, [])
  useEffect(() => {
    axiosInstance
      .get(`/poc?company_id=${company_id}&status_id=1`)
      .then((response) => {
        setContacts(response?.data?.data)
      })
  }, [company_id])

  const handleUpdate = () => {
    if (title === '')
      return toast('Please enter a job title', { type: 'error' })
    if (description === '')
      return toast('Please enter a job summary', { type: 'error' })
    if (job_type === '')
      return toast('Please select a Employment type', { type: 'error' })
    if (active_vacancy === '')
      return toast('Please enter a active vacancy', { type: 'error' })
    if (client === '') return toast('Please select a client', { type: 'error' })
    if (time_shift === '')
      return toast('Please select a time shift', { type: 'error' })
    if (!poc_id || poc_id === '')
      return toast('Please select a contact', { type: 'error' })

    if (recruiter_id && recruiter_id === '')
      return toast('Please select a recruiter', { type: 'error' })

    if (countries.length === 0)
      return toast('Please select atleast one country', { type: 'error' })

    dispatch({ type: SHOW_MODAL })
    dispatch({ type: SET_REVIEW_POST_MODAL, payload: '' })
  }

  const handleClientChange = (e) => {
    const client = companies.find(
      (client) => client.name.toString() === e.target.value
    )
    dispatch({
      type: SET_COMPANY_ID,
      payload: JSON.stringify(client),
    })
  }

  const handleContactChange = (e) => {
    const contact = contacts.find(
      (contact) => contact.id.toString() === e.target.value
    )
    dispatch({
      type: SET_POC_ID,
      payload: JSON.stringify(contact),
    })
  }

  const handleJobRatingGroupChange = (e) => {
    const ratingGroup = jobRatingGroups.find(
      (el) => el.id.toString() === e.target.value
    )

    dispatch({
      type: SET_JOB_RATING_GROUP,
      payload: JSON.stringify(ratingGroup),
    })
  }
  const handleInterviewRatingGroupChange = (e) => {
    const ratingGroup = interviewRatingGroups.find(
      (el) => el.id.toString() === e.target.value
    )

    dispatch({
      type: SET_INTERVIEW_RATING_GROUP,
      payload: JSON.stringify(ratingGroup),
    })
  }

  const onClose = () => {
    dispatch({ type: SET_JOB_TITLE, payload: initialState.jobTitle })
    dispatch({ type: SET_JOB_TYPE, payload: initialState.jobType })
    dispatch({ type: SET_ACTIVE_VACANCY, payload: initialState.activeVacancy })
    dispatch({
      type: SET_VACANCY_FOR_POOLING,
      payload: initialState.vacancyForPooling,
    })
    dispatch({ type: SET_TIME_SHIFT, payload: initialState.timeShift })
    dispatch({ type: SET_JOB_DESCRIPTION, payload: initialState.description })
    dispatch({ type: HIDE_MODAL })
  }

  return (
    <Modal
      title="Job Details"
      btnFunc={handleUpdate}
      btnTitle="Update"
      onClose={onClose}
    >
      <DashboardInput
        label="Job Title"
        placeholder="e.g. Graphic Designer, Software Engineer"
        name="jobTitle"
        handleChange={(e) =>
          dispatch({ type: SET_JOB_TITLE, payload: e.target.value })
        }
        type="text"
        value={title}
        height="2.5rem"
      />

      {/* employment type field needs options and state created */}
      <SingleDropdownSelect
        onChange={(e) =>
          dispatch({ type: SET_JOB_TYPE, payload: e.target.value })
        }
        value={job_type}
        title="Employment Type"
      >
        <option>Choose an option</option>
        <option value="full-time">Full time</option>
        <option value="part-time">Part time</option>
      </SingleDropdownSelect>

      {/* creating double type input box */}
      <Flex width="100%" mobileDirection="column">
        <FilterCheckBox
          checked={polling}
          size="small"
          pos="absolute"
          top="-.05rem"
          right="-1.75rem"
          label="For Pooling"
          name="ProfessionalHeadline"
          onChange={handlePooling}
        />
        <DashboardInput
          label="Active Vacancies"
          placeholder={0}
          value={active_vacancy}
          handleChange={(e) =>
            dispatch({ type: SET_ACTIVE_VACANCY, payload: e.target.value })
          }
          name="activeVacancies"
          type="number"
          height="2.5rem"
        />
        {polling && (
          <DashboardInput
            label="Vacancy for Pooling"
            placeholder={0}
            value={vacancy_for_pooling}
            handleChange={(e) =>
              dispatch({
                type: SET_VACANCY_FOR_POOLING,
                payload: e.target.value,
              })
            }
            name="vacancyForPooling"
            type="number"
            height="2.5rem"
          />
        )}
      </Flex>

      {/* employment type field needs options and state created */}
      <SingleDropdownSelect
        onChange={(e) =>
          dispatch({ type: SET_TIME_SHIFT, payload: e.target.value })
        }
        value={time_shift}
        title="Time Shift"
      >
        <option>Choose an option</option>
        <option value="Morning Shift - PH TIME">Morning Shift - PH TIME</option>
        <option value="Afternoon Shift - PH TIME">
          Afternoon Shift - PH TIME
        </option>
        <option value="Night Shift - PH TIME">Night Shift - PH TIME</option>
        <option value="Rotational Shift - PH TIME">
          Rotational Shift - PH TIME
        </option>
      </SingleDropdownSelect>

      {/*  */}
      <RichTextbox
        label="Job Summary"
        removeButtons={[
          'paragraph',
          'bold',
          'underline',
          'italic',
          '|',
          'ul',
          'ol',
          '|',
          'link',
          '|',
          'undo',
          'redo',
        ]}
        content={description}
        setContent={handleDescription}
      />

      <SingleDropdownSelect
        onChange={(e) => dispatch(setRecruiterId(e.target.value))}
        title="Recruiter"
        value={recruiter_id}
      >
        <option>Choose an option</option>
        {recruiters?.resp?.map((recruiter) =>
          recruiter.Recruiter.name ? (
            <option
              key={recruiter?.Recruiter?.id}
              value={recruiter?.Recruiter?.id}
            >
              {recruiter?.Recruiter?.name}
            </option>
          ) : null
        )}
      </SingleDropdownSelect>

      <SingleDropdownSelect
        onChange={handleClientChange}
        title="Client"
        value={client}
      >
        <option>Choose an option</option>
        {companies?.map((item) => (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        ))}
      </SingleDropdownSelect>

      <SingleDropdownSelect
        onChange={handleContactChange}
        title="Point of Contact"
        value={poc_id}
      >
        <option>Choose an option</option>
        {contacts?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </SingleDropdownSelect>

      <MultiSelectWrapper>
        <label>Vacancy available on countries</label>
        <Select
          placeholder="Select from the options to add more..."
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          className="slider"
          options={allCountries}
          value={countries}
          onChange={(value) =>
            dispatch({ type: SET_AVAILABLE_COUNTRIES, payload: value })
          }
          isMulti
        />
      </MultiSelectWrapper>

      <SingleDropdownSelect
        onChange={handleJobRatingGroupChange}
        title="Job Rating Group"
        value={job_rating_group_id}
      >
        <option>Choose an option</option>
        {jobRatingGroups?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </SingleDropdownSelect>

      <SingleDropdownSelect
        onChange={handleInterviewRatingGroupChange}
        title="Interview Rating Group"
        value={interview_rating_group_id}
      >
        <option>Choose an option</option>
        {interviewRatingGroups?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </SingleDropdownSelect>

      {/* Toggle button */}
      <JobToggleButton
        value={requires_coaching}
        onClick={() => dispatch({ type: SET_REQUIRES_COACHING })}
        label="This listing requires coaching"
        mt=".875rem"
        mb="1.5rem"
      />

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Modal>
  )
}

export default JobDetailsModal

export const MultiSelectWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;

  label {
    font-size: 0.75rem;
    color: #31374f;
    font-weight: 300;
  }

  .slider {
    width: 100%;

    .css-1s2u09g-control {
      border: 1px solid #aeb3c9;
      background-color: #fff;
      border-radius: 0;
      /* padding: 1rem; */
      font-size: 0.75rem;
      font-weight: 300;
    }

    .css-1pahdxg-control {
      border: 1px solid #1a1a1a;
      box-shadow: 0 0 0 1px #1a1a1a;
      border-radius: 0;
      font-size: 0.75rem;
      font-weight: 300;
    }

    .css-1rhbuit-multiValue {
      background-color: #f2f3f8;
      border-radius: 3px;
    }
  }
`
