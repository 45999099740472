import React from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'

import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'

import {
  setModal,
  fetchRatingQuestionRecord,
} from '../../../../store/actions/ratingActions'

const ActionsMenu = ({ setShowMenu, value }) => {
  const dispatch = useDispatch()

  const handleOutsideClick = () => setShowMenu(false)

  const handleUpdateRatingQuestionModal = (e) => {
    e.stopPropagation()

    dispatch(fetchRatingQuestionRecord(`/rating-questions?id=${value.id}`))
    dispatch(setModal('updateRatingQuestion'))
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <TalentCardPopout maxW="199px" width="199px" top="0" left="55%">
        <TalentCardPopList
          onClick={handleUpdateRatingQuestionModal}
          text="Edit Rating Question"
        />
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ActionsMenu
