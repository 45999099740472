import React, { useMemo } from 'react'

import styled from 'styled-components'

const RangeInput = ({ id, value, onChange }) => {
  const status = useMemo(
    () => ({
      1: 'Bad',
      2: 'Average',
      3: 'Good',
    }),
    []
  )

  return (
    <RangeInputContainer>
      <div className="slider">
        <span>{`Value: ${value}`}</span>
        <div>
          <input
            data-questionid={id}
            onChange={onChange}
            value={value}
            className={`slider ${status[value]}`}
            type="range"
            min="1"
            max="3"
          />
        </div>
      </div>
    </RangeInputContainer>
  )
}

export default RangeInput

const RangeInputContainer = styled.div`
  height: 3.5rem;
  display: flex;
  justify-content: space-between;

  .slider {
    cursor: pointer;
    margin-right: 8px;
    p {
      font-size: 0.625rem;
      font-weight: 500;
      color: #acb2cc;
    }

    span {
      font-size: 0.625rem;
      font-weight: 500;
      color: #acb2cc;
    }

    .slider {
      height: 2px;
      -webkit-appearance: none;
      outline: none;
      border: none;
      background: linear-gradient(
        90deg,
        #e4f79c -1.65%,
        #cae463 23.76%,
        #a2dd74 49.71%,
        #7dd585 76.18%,
        #5cca95 100%
      );
      border-radius: 100px;
      transition: all 0.3s ease-in-out;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0.875rem;
        height: 0.875rem;
        border-radius: 50%;
        background: #ddf382;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      &.Bad {
        &::-webkit-slider-thumb {
          background: #cae463;
        }
      }

      &.Average {
        &::-webkit-slider-thumb {
          background: #7dd585;
        }
      }

      &.Good {
        &::-webkit-slider-thumb {
          background: #5cca95;
          box-shadow: 0px 0px 0px 12px rgba(92, 202, 149, 0.2);
        }
      }
    }
  }
`
