import styled from 'styled-components'

export const P = styled.p`
  font-size: ${(props) => (props.size ? props.size : '0.875rem')};
  font-weight: ${(props) => (props.weight ? props.weight : '300')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.5')};
  color: ${(props) => (props.color ? props.color : '#0E1324')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.03em'};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : 'none'};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  border-bottom: ${({ borderBottom }) => borderBottom || 'none'};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace}`};
  width: ${(props) => (props.width ? props.width : 'auto')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};

  ${(props) =>
    props.withHover &&
    `
    &:hover {
      color: blue;
      text-decoration: underline;
    }
  `}
`

export const H2 = styled.h2`
  font-size: ${(props) => (props.size ? props.size : '1.5rem')};
  text-align: ${(props) => (props.align ? props.align : 'center')};
  font-weight: ${(props) => (props.weight ? props.weight : '300')};
  color: ${(props) => (props.color ? props.color : '#31374f')};
  margin-bottom: ${(props) => (props.mb ? props.mb : '1.5rem')};
  margin-top: ${(props) => (props.mt ? props.mt : '0')};
`

export const H6 = styled.h6`
  font-size: ${(props) => (props.size ? props.size : '1rem')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  font-weight: ${(props) => (props.weight ? props.weight : '300')};
  color: ${(props) => (props.color ? props.color : '#31374f')};
  margin-bottom: ${(props) => (props.mb ? props.mb : '1rem')};
  margin-top: ${(props) => (props.mt ? props.mt : '0')};
`
