import ratings from '../types/ratings'
import { updateList } from '../../utils/stateHelpers'

const initialState = {
  modal: '',
  ratings: [],
  ratingQuestions: [],
  selectedRecord: {
    ratingGroup: {},
    ratingQuestion: {},
  },
  pagination: {},
  loading: false,
  notification: {},
  queryParams: {
    searchTerm: '',
    pageNumber: 1,
    pageSize: 10,
  },
}

const ratingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ratings.SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ratings.SET_MODAL:
      return {
        ...state,
        loading: false,
        modal: action.payload,
      }
    case ratings.SET_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message: action.payload,
        },
      }
    case ratings.SET_PAGINATION_OPTIONS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }
    case ratings.SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...action.payload,
        },
      }
    case ratings.SET_SELECTED_RECORD:
      return {
        ...state,
        selectedRecord: {
          ...state.selectedRecord,
          [action.key]: { ...action.payload },
        },
      }
    case ratings.RESET_NOTIFICATION: {
      return {
        ...state,
        notification: {},
      }
    }

    case ratings.FETCH_RATING_GROUP_LIST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ratings.FETCH_RATING_GROUP_LIST_SUCCESS:
      return {
        ...state,
        pagination: action.payload.pagination,
        ratings: action.payload.data,
        loading: false,
      }
    case ratings.FETCH_RATING_GROUP_LIST_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }

    case ratings.FETCH_RATING_QUESTION_LIST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ratings.FETCH_RATING_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        pagination: action.payload.pagination,
        ratingQuestions: action.payload.data,
        loading: false,
      }
    case ratings.FETCH_RATING_QUESTION_LIST_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }

    case ratings.FETCH_RATING_GROUP_RECORD_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ratings.FETCH_RATING_GROUP_RECORD_SUCCESS:
      return {
        ...state,
        selectedRecord: {
          ...state.selectedRecord,
          ratingGroup: {
            ...state.selectedRecord.ratingGroup,
            ...action.payload,
          },
        },
        loading: false,
      }
    case ratings.FETCH_RATING_GROUP_RECORD_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }

    case ratings.FETCH_RATING_QUESTION_RECORD_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ratings.FETCH_RATING_QUESTION_RECORD_SUCCESS:
      return {
        ...state,
        selectedRecord: {
          ...state.selectedRecord,
          ratingQuestion: {
            ...state.selectedRecord.ratingQuestion,
            ...action.payload,
          },
        },
        loading: false,
      }
    case ratings.FETCH_RATING_QUESTION_RECORD_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }

    case ratings.SAVE_RATING_GROUP_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ratings.ADD_RATING_GROUP_DATA_SUCCESS:
      return {
        ...state,
        ratings: [action.payload.data, ...state.ratings],
        modal: '',
        notification: {
          value: 'success',
          message: action.payload.message,
        },
        selectedRecord: {
          ...state.selectedRecord,
          ratingGroup: {},
        },
        loading: false,
      }
    case ratings.UPDATE_RATING_GROUP_DATA_SUCCESS: {
      return {
        ...state,
        ratings: updateList(state.ratings, 'id', action.payload.data),
        notification: {
          value: 'success',
          message: action.payload.message,
        },
        selectedRecord: {
          ...state.selectedRecord,
          ratingGroup: {},
        },
        loading: false,
        modal: '',
      }
    }
    case ratings.SAVE_RATING_GROUP_DATA_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }

    case ratings.SAVE_RATING_QUESTION_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ratings.ADD_RATING_QUESTION_DATA_SUCCESS:
      return {
        ...state,
        ratingQuestions: [action.payload.data, ...state.ratingQuestions],
        modal: '',
        notification: {
          value: 'success',
          message: action.payload.message,
        },
        selectedRecord: {
          ...state.selectedRecord,
          ratingQuestion: {},
        },
        loading: false,
      }
    case ratings.UPDATE_RATING_QUESTION_DATA_SUCCESS: {
      return {
        ...state,
        ratingQuestions: updateList(
          state.ratingQuestions,
          'id',
          action.payload.data
        ),
        notification: {
          value: 'success',
          message: action.payload.message,
        },
        selectedRecord: {
          ...state.selectedRecord,
          ratingQuestion: {},
        },
        loading: false,
        modal: '',
      }
    }
    case ratings.SAVE_RATING_QUESTION_DATA_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }

    default:
      return state
  }
}

export default ratingReducer
