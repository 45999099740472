import React from 'react'

import Tags from '../../../../components/Tags'

const TotalJobs = ({ aggregatedValue }) => {
  return (
    <strong>
      Total Active Jobs:{' '}
      <Tags text={<strong>{aggregatedValue}</strong>} status="active" />
    </strong>
  )
}

export default TotalJobs
