import React from 'react'
import styled from 'styled-components'

import RatingGroupList from './RatingGroupList'

const RatingGroupListWrapper = ({ fetchRatingGroupList }) => {
  return (
    <StyledRatingGroupListWrapper>
      <RatingGroupList fetchRatingGroupList={fetchRatingGroupList} />
    </StyledRatingGroupListWrapper>
  )
}

export default RatingGroupListWrapper

const StyledRatingGroupListWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 16rem);
  background: #fff;
  border: 0.5px solid #aeb3c9;
  border-bottom: none;
  margin-top: 1rem;
  overflow-y: hidden;
`
