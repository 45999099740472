import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import Table from '../../../../components/Table'
import Flex from '../../../../components/layouts/Flex'
import { P } from '../../../../style/Typography.style'
import TableAction from './TableAction'

import {
  setModal,
  setPagination,
  setQueryParams,
} from '../../../../store/actions/ratingActions'

import { convertObjectToUrlParams } from '../../../../utils/stateHelpers'

const RatingGroupList = ({ fetchRatingGroupList }) => {
  const { ratings, pagination, loading, queryParams } = useSelector(
    (state) => state.ratings
  )

  const dispatch = useDispatch()

  const url = useMemo(
    () => `/rating-groups/paged?${convertObjectToUrlParams(queryParams)}`,
    [queryParams]
  )

  const fetchData = () => {
    fetchRatingGroupList(url)
  }

  const handlePagination = (option) => {
    dispatch(setPagination(option))
    if ('page' in option) {
      dispatch(setQueryParams({ pageNumber: option.page }))
    }
    if ('pageSize' in option) {
      dispatch(setQueryParams({ pageSize: option.pageSize }))
      dispatch(setQueryParams({ pageNumber: 1 }))
    }
  }

  useEffect(() => {
    fetchData()
  }, [queryParams])

  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'actions',
        disableSortBy: true,
        Cell: ({ value }) => <TableAction value={value} />,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Rating Type',
        accessor: 'rating_type_name',
      },
    ],
    []
  )

  const tableData = ratings?.map(
    (item) =>
      ({
        actions: item,
        name: item.name,
        rating_type_name: item.rating_type_name,
      } || '')
  )

  return (
    <StyledRatingGroupList>
      {ratings?.length > 0 ? (
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          totalPage={pagination?.pageCount}
          currentPage={pagination?.page}
          pageSize={pagination?.pageSize}
          rowCount={pagination?.rowCount}
          fetchData={fetchData}
          setNewPageSize={(e) => handlePagination({ pageSize: e })}
          setCurrentPage={(e) => handlePagination({ page: e })}
          url={url}
          noData="User Types is empty"
          height="calc(105vh - 16.3rem)"
          onClick={() => dispatch(setModal(''))}
        />
      ) : (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>Rating Group List is empty</P>
        </Flex>
      )}
    </StyledRatingGroupList>
  )
}

export default RatingGroupList

const StyledRatingGroupList = styled.div`
  width: 100%;
  height: 100%;
`
