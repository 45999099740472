import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import DashboardWrapper from './DashboardWrapper'
import RecruitmentStageFunnelChart from './components/RecruitmentStageFunnelChart'
import RecruitmentStageFunnelList from './components/RecruitmentStageFunnelList'
import AcceptedApplicantList from './components/AcceptedApplicantList'
import ChannelsChart from './components/ChannelsChart'
import RecruitmentFunnelTrendChart from './components/RecruitmentFunnelTrendChart'
import RecruitmentChannelsChart from './components/RecruitmentChannelsChart'
import DashboardFilter from './components/DashboardFilter'
import GroupWrapper from './components/GroupWrapper'
import InterviewTableWrapper from './components/InterviewTableWrapper'
import KPIChart from './components/KPIChart'

import Flex from '../../../components/layouts/Flex'

import { setDownload } from '../../../store/actions/dashboardActions'

import PDFDownloader from '../../../lib/PDFDownloader'

const Dashboard = () => {
  const [footer, setFooter] = useState('')

  const dispatch = useDispatch()

  const download = useSelector((state) => state.dashboard.download)
  const footerData = useSelector((state) => state.dashboard.footer)
  const dates = footerData.dates

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type

  const componentRef = useRef()

  useEffect(() => {
    if (download === 'pdf') {
      let fileDate = ''
      const startDate = dates[0].toLocaleDateString().replace(/\//g, '')
      const endDate = dates[1].toLocaleDateString().replace(/\//g, '')
      if (startDate !== endDate) fileDate = `${startDate}-${endDate}`
      else fileDate = startDate
      const filename = `Dashboard_${fileDate}.pdf`

      PDFDownloader.downloadPDF(componentRef, filename).then(() =>
        dispatch(setDownload(''))
      )
    }
  }, [download])

  useEffect(() => {
    let _footer = ''
    const startDate = dates[0].toLocaleDateString().replace(/\//g, '/')
    const endDate = dates[1].toLocaleDateString().replace(/\//g, '/')
    _footer += `Start Date: ${startDate} <br>`
    _footer += `End Date: ${endDate} <br>`
    _footer += `Vacancy: ${footerData.job} <br>`
    _footer += `Client: ${footerData.company}`
    setFooter(_footer)
  }, [footerData])

  return (
    <DashboardWrapper>
      <span>
        <GroupWrapper title="Report Filters">
          <Flex
            direction="column"
            padding="0 1rem 1rem 0"
            width="100%"
            gap="1rem"
            height="28vh"
            justify="center"
            items="center"
          >
            <DashboardFilter />
          </Flex>
        </GroupWrapper>
      </span>
      <span ref={componentRef} className="report-container">
        <GroupWrapper title="Reports Overview">
          <Flex
            padding="0 1rem 1rem 1rem"
            direction="column"
            width="100%"
            height="50vh"
            justify="center"
            bB="1px solid #AEB3C9"
          >
            <RecruitmentStageFunnelChart />
          </Flex>
          <Flex width="100%" height="100%">
            <Flex
              direction="column"
              width="100%"
              height="100%"
              padding="1rem"
              overflowY="off"
              bB="1px solid #AEB3C9"
            >
              <RecruitmentStageFunnelList />
            </Flex>
          </Flex>

          <Flex width="100%" height="100%">
            <Flex
              direction="column"
              width="100%"
              height="100%"
              padding="1rem"
              overflowY="off"
              bB="1px solid #AEB3C9"
            >
              <AcceptedApplicantList />
            </Flex>
          </Flex>

          {role !== 'poc' && (
            <Flex width="100%" direction="column" height="50vh" padding="1rem">
              <ChannelsChart />
              <StyledBorderBottom />
            </Flex>
          )}

          <Flex width="100%" direction="column" height="50vh" padding="1rem">
            <KPIChart />
          </Flex>
        </GroupWrapper>

        <GroupWrapper title="Quarterly Reports">
          <Flex
            width="100%"
            direction="column"
            height="65vh"
            padding="1rem"
            justify="center"
            bB="1px solid #AEB3C9"
          >
            <RecruitmentFunnelTrendChart />
          </Flex>
          {role !== 'poc' && (
            <Flex width="100%" direction="column" height="50vh" padding="1rem">
              <RecruitmentChannelsChart />
            </Flex>
          )}
        </GroupWrapper>
        {role === 'poc' && (
          <GroupWrapper title="Interviews">
            <Flex
              direction="column"
              width="100%"
              height="100%"
              overflowY="off"
              bB="none"
            >
              <InterviewTableWrapper />
            </Flex>
          </GroupWrapper>
        )}
        {download === 'pdf' && (
          <Footer>
            <div dangerouslySetInnerHTML={{ __html: footer }} />
          </Footer>
        )}
      </span>
    </DashboardWrapper>
  )
}

export default Dashboard

const Footer = styled.div`
  padding-left: 5px;
`

const StyledBorderBottom = styled.div`
  border-bottom: 1px solid rgb(174, 179, 201);
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
`
