import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useToasts } from 'react-toast-notifications'

import Heading from '../../../components/dashboard/Heading'
import HeaderFilter from './components/HeaderFilter'
import RatingGroupListWrapper from './components/RatingGroupListWrapper'
import SaveRatingGroup from './components/SaveRatingGroup'

import {
  fetchRatingGroupList,
  resetNotification,
} from '../../../store/actions/ratingActions'

import { convertObjectToUrlParams } from '../../../utils/stateHelpers'

const RatingGroups = () => {
  const dispatch = useDispatch()

  const { addToast } = useToasts()

  const { queryParams, modal, notification } = useSelector(
    (state) => state.ratings
  )

  const handleFetchList = (queryParams) => {
    dispatch(fetchRatingGroupList(queryParams))
  }

  useEffect(() => {
    if (notification.value === 'success') {
      addToast(notification?.message, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
    if (notification.value === 'error') {
      addToast(notification?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    dispatch(resetNotification())
  }, [notification.value])

  useEffect(() => {
    dispatch(fetchRatingGroupList(convertObjectToUrlParams(queryParams)))
  }, [queryParams])

  return (
    <>
      <Heading title="Rating Groups" />
      <HeaderFilter fetchList={handleFetchList} />
      {/* Contains list of rating groups */}
      <RatingGroupListWrapper fetchRatingGroupList={handleFetchList} />
      {/* Modal section */}
      {(modal === 'createRatingGroup' || modal === 'updateRatingGroup') && (
        <SaveRatingGroup />
      )}
    </>
  )
}

export default RatingGroups
