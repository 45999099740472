import React, { memo, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import DashboardButton from '../../DashboardButton'
import EmptyWrapper from '../../../EmptyWrapper'

import UpdateQuestionNotes from './components/UpdateQuestionNotes'
import RangeInput from './components/RangeInput'
import MarkDetails from '../../../MarkDetails'

import { P } from '../../../../style/Typography.style'

import axiosInstance from '../../../../services/config/AxiosIntance'

const JobRatingGroups = ({ type }) => {
  const { selectedInterview } = useSelector((state) => state.requisitions)
  const { addToast } = useToasts()

  const [jobQuestions, setJobQuestions] = useState([])
  const [answeredQuestions, setAnsweredQuestions] = useState([])
  const [selectedQuestion, setSelectedQuestion] = useState('')
  const [noteModal, setNoteModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      if (answeredQuestions.length <= 0)
        return addToast('Please provide your answers before submitting.', {
          appearance: 'error',
          autoDismiss: true,
        })

      const submittedQuestions = answeredQuestions.map((q) => ({
        ...q,
        answer: JSON.stringify({
          answer: q.answer,
        }),
      }))

      const result = await axiosInstance.post(
        `/interview-ratings/save`,
        submittedQuestions
      )
      addToast(result?.data?.message, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (error) {
      addToast('Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleRangeChanged = (e) => {
    const questionId = e.target.dataset.questionid

    const payload = {
      interview_id: selectedInterview.id.toString(),
      question_id: questionId,
      answer: e.target.value,
    }

    const updatedAnswers = answeredQuestions.map((existingAnswer) => {
      if (existingAnswer.question_id === questionId) {
        return {
          ...existingAnswer,
          answer: e.target.value,
        }
      }
      return existingAnswer
    })

    if (!updatedAnswers.some((answer) => answer.question_id === questionId)) {
      updatedAnswers.push(payload)
    }

    setAnsweredQuestions(updatedAnswers)

    const updatedJobQuestions = jobQuestions.map((question) => {
      if (question.question_id === parseInt(questionId)) {
        return {
          ...question,
          answer: e.target.value,
        }
      } else {
        return question
      }
    })

    setJobQuestions(updatedJobQuestions)
  }

  const handleMarkChanged = (id, value) => {
    const payload = {
      interview_id: selectedInterview.id.toString(),
      question_id: id.toString(),
      answer: value,
    }

    const updatedAnswers = answeredQuestions.map((existingAnswer) => {
      if (existingAnswer.question_id === id.toString()) {
        return {
          ...existingAnswer,
          answer: value,
        }
      }
      return existingAnswer
    })

    if (
      !updatedAnswers.some((answer) => answer.question_id === id.toString())
    ) {
      updatedAnswers.push(payload)
    }

    setAnsweredQuestions(updatedAnswers)

    const updatedJobQuestions = jobQuestions.map((question) => {
      if (question.question_id === parseInt(id)) {
        return {
          ...question,
          answer: value,
        }
      } else {
        return question
      }
    })

    setJobQuestions(updatedJobQuestions)
  }

  const handleQuestionClicked = (e) => {
    const questionId = e.currentTarget.dataset.questionid
    const selected = answeredQuestions.filter(
      (q) => parseInt(q.question_id) === parseInt(questionId)
    )
    setSelectedQuestion(selected)
    setNoteModal(true)
  }

  useEffect(() => {
    axiosInstance
      .get(
        `/rating-questions?interview_id=${selectedInterview?.id}&rating_type_name=${type}`
      )
      .then((res) => {
        setLoading(true)
        const result = res?.data?.resp?.map((e) => ({
          interview_id: selectedInterview?.id,
          question_id: e.id,
          question: e.question,
          answer: e.answer && JSON.parse(e.answer)?.answer,
          notes: e.notes,
        }))

        setJobQuestions(result)

        const defaultAnswers = res?.data?.resp?.map((e) => ({
          interview_id: selectedInterview?.id.toString(),
          question_id: e.id.toString(),
          answer: e.answer && JSON.parse(e.answer)?.answer,
          notes: e.notes,
        }))
        setAnsweredQuestions(defaultAnswers)
      })
      .catch(() =>
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true,
        })
      )
      .finally(() => setLoading(false))
  }, [selectedInterview.id])

  return (
    <StyledJobRatingGroups>
      {loading && <EmptyWrapper message="Loading..." />}
      {jobQuestions.length > 0 ? (
        <>
          <ul>
            {jobQuestions.map((jobQuestion, i) => (
              <li key={jobQuestion.question_id}>
                <QuestionWrap>
                  {jobQuestion?.notes === '<p><br></p>' ||
                    (answeredQuestions[i].notes === '<p><br></p>' && (
                      <span>*</span>
                    ))}
                  <P
                    withHover
                    cursor="pointer"
                    textAlign="justify"
                    data-questionid={jobQuestion.question_id}
                    onClick={handleQuestionClicked}
                  >
                    {jobQuestion.question}
                  </P>
                </QuestionWrap>
                {type === 'Interview Rating' ||
                selectedInterview.stages === 'applicant_screening' ? (
                  <MarkDetails
                    isCorrect={jobQuestion?.answer}
                    textPosition="left"
                    exactBooleanCheck
                    onCheck={() =>
                      handleMarkChanged(jobQuestion.question_id, true)
                    }
                    onUncheck={() => {
                      handleMarkChanged(jobQuestion.question_id, false)
                    }}
                  />
                ) : (
                  <RangeInput
                    id={jobQuestion.question_id}
                    value={jobQuestion.answer}
                    onChange={handleRangeChanged}
                  />
                )}
              </li>
            ))}
          </ul>
          <DashboardButton text="Submit" onClick={handleSubmit} notIcon />
        </>
      ) : (
        <EmptyWrapper
          message={`There are no ${type} attached to this vacancy.`}
        />
      )}

      {noteModal && (
        <UpdateQuestionNotes
          answeredQuestions={answeredQuestions}
          setAnsweredQuestions={setAnsweredQuestions}
          currentQuestion={selectedQuestion}
          questionId={selectedQuestion}
          setNoteModal={setNoteModal}
        />
      )}
    </StyledJobRatingGroups>
  )
}

export default memo(JobRatingGroups)

const QuestionWrap = styled.div`
  display: flex;
  gap: 0.25rem;

  span {
    color: red;
  }
`

const StyledJobRatingGroups = styled.div`
  height: calc(70vh - 2rem);

  width: 100%;
  padding: 0 1rem;
  padding-bottom: 2rem;
  position: relative;
  list-style: none;

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
  }

  li {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  div {
    text-align: justify;
  }

  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    content: '';
    position: absolute;
  }
`
