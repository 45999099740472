import React, { useEffect } from 'react'

import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import Heading from '../../../components/dashboard/Heading'
import HeaderFilter from './components/HeaderFilter'
import RatingQuestionListWrapper from './components/RatingQuestionListWrapper'
import SaveRatingQuestion from './components/SaveRatingQuestion'

import {
  fetchRatingGroupRecord,
  fetchRatingQuestionList,
  resetNotification,
} from '../../../store/actions/ratingActions'

import { convertObjectToUrlParams } from '../../../utils/stateHelpers'

const RatingQuestions = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { addToast } = useToasts()

  const { queryParams, modal, notification, selectedRecord } = useSelector(
    (state) => state.ratings
  )

  const handleFetchList = (queryParams) => {
    dispatch(fetchRatingQuestionList(queryParams))
  }

  useEffect(() => {
    dispatch(fetchRatingGroupRecord(`/rating-groups?id=${id}`))
  }, [])

  useEffect(() => {
    if (notification.value === 'success') {
      addToast(notification?.message, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
    if (notification.value === 'error') {
      addToast(notification?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    dispatch(resetNotification())
  }, [notification.value])

  useEffect(() => {
    dispatch(fetchRatingQuestionList(convertObjectToUrlParams(queryParams)))
  }, [queryParams])

  return (
    <>
      <Heading
        title={`Rating Questions for ${selectedRecord?.ratingGroup?.name}`}
      />
      <HeaderFilter fetchList={handleFetchList} />

      {/* Contains list of rating questions */}
      <RatingQuestionListWrapper fetchRatingQuestionList={handleFetchList} />

      {/* Modal section */}
      {(modal === 'createRatingQuestion' ||
        modal === 'updateRatingQuestion') && <SaveRatingQuestion />}
    </>
  )
}

export default RatingQuestions
