const ratings = {
  SET_LOADING: 'SET_LOADING',
  SET_MODAL: 'SET_MODAL',
  SET_ERROR: 'SET_ERROR',
  SET_PAGINATION_OPTIONS: 'SET_PAGINATION_OPTIONS',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
  SET_SELECTED_RECORD: 'SET_SELECTED_RECORD',
  RESET_NOTIFICATION: 'RESET_NOTIFICATION',

  FETCH_RATING_GROUP_LIST_LOADING: 'FETCH_RATING_GROUP_LIST_LOADING',
  FETCH_RATING_GROUP_LIST_SUCCESS: 'FETCH_RATING_GROUP_LIST_SUCCESS',
  FETCH_RATING_GROUP_LIST_ERROR: 'FETCH_RATING_GROUP_LIST_ERROR',

  FETCH_RATING_QUESTION_LIST_LOADING: 'FETCH_RATING_QUESTION_LIST_LOADING',
  FETCH_RATING_QUESTION_LIST_SUCCESS: 'FETCH_RATING_QUESTION_LIST_SUCCESS',
  FETCH_RATING_QUESTION_LIST_ERROR: 'FETCH_RATING_QUESTION_LIST_ERROR',

  SAVE_RATING_GROUP_DATA_LOADING: 'SAVE_RATING_GROUP_DATA_LOADING',
  ADD_RATING_GROUP_DATA_SUCCESS: 'ADD_RATING_GROUP_DATA_SUCCESS',
  UPDATE_RATING_GROUP_DATA_SUCCESS: 'UPDATE_RATING_GROUP_DATA_SUCCESS',
  SAVE_RATING_GROUP_DATA_ERROR: 'SAVE_RATING_GROUP_DATA_ERROR',

  SAVE_RATING_QUESTION_DATA_LOADING: 'SAVE_RATING_QUESTION_DATA_LOADING',
  ADD_RATING_QUESTION_DATA_SUCCESS: 'ADD_RATING_QUESTION_DATA_SUCCESS',
  UPDATE_RATING_QUESTION_DATA_SUCCESS: 'UPDATE_RATING_QUESTION_DATA_SUCCESS',
  SAVE_RATING_QUESTION_DATA_ERROR: 'SAVE_RATING_QUESTION_DATA_ERROR',

  FETCH_RATING_GROUP_RECORD_LOADING: 'FETCH_RATING_GROUP_RECORD_LOADING',
  FETCH_RATING_GROUP_RECORD_SUCCESS: 'FETCH_RATING_GROUP_RECORD_SUCCESS',
  FETCH_RATING_GROUP_RECORD_ERROR: 'FETCH_RATING_GROUP_RECORD_ERROR',

  FETCH_RATING_QUESTION_RECORD_LOADING: 'FETCH_RATING_QUESTION_RECORD_LOADING',
  FETCH_RATING_QUESTION_RECORD_SUCCESS: 'FETCH_RATING_QUESTION_RECORD_SUCCESS',
  FETCH_RATING_QUESTION_RECORD_ERROR: 'FETCH_RATING_QUESTION_RECORD_ERROR',
}

export default ratings
