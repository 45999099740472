import React from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'

import {
  setModal,
  fetchRatingGroupRecord,
  setQueryParams,
  setSelectedRecord,
} from '../../../../store/actions/ratingActions'

const ActionsMenu = ({ setShowMenu, value }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOutsideClick = () => setShowMenu(false)

  const handleUpdateRatingGroupModal = (e) => {
    e.stopPropagation()
    dispatch(
      fetchRatingGroupRecord(`/rating-groups?id=${value.id}`, {
        rating_type_name: value.rating_type_name,
      })
    )
    dispatch(setModal('updateRatingGroup'))
  }

  const handleViewRatingQuestions = (e) => {
    e.stopPropagation()
    dispatch(setQueryParams({ rating_group_id: value.id }))
    dispatch(setSelectedRecord(value, 'ratingGroup'))
    navigate(`/rating-groups/${value.id}`)
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <TalentCardPopout maxW="199px" width="199px" top="0" left="55%">
        <TalentCardPopList
          onClick={handleUpdateRatingGroupModal}
          text="Edit Rating Group"
        />
        <TalentCardPopList
          onClick={handleViewRatingQuestions}
          text="View Rating Questions"
        />
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ActionsMenu
