import React, { useMemo, useEffect, useState, memo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'

import axiosInstance from '../../../../services/config/AxiosIntance'

import Table from '../../../../components/Table'
import Loader from '../../../../components/Loader'
import Flex from '../../../../components/layouts/Flex'
import DashboardItemTitle from './DashboardItemTitle'
import NoData from './NoData'

import CSVDownloader from '../../../../lib/CSVDownloader'

const AcceptedApplicantList = () => {
  const { addToast } = useToasts()

  const filterDates = useSelector(
    (state) => state.dashboard.filters.dates,
    shallowEqual
  )

  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [rowCount, setRowCount] = useState(0)

  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    fetchData()
  }, [filterDates])

  const fetchData = async () => {
    try {
      setLoading(true)

      const queryFilter = {
        dates: filterDates.toString(),
      }
      const queryParams = new URLSearchParams(queryFilter).toString()
      const fetchedPageUrl = `/interviews/accepted-applicants/fetchPage?${queryParams}&pageNumber=${currentPage}&pageSize=${pageSize}`

      const result = await axiosInstance.get(fetchedPageUrl)

      setTableData(result?.data?.data)

      setTotalPage(result?.data?.pagination?.pageCount)
      setRowCount(result?.data?.pagination?.rowCount)
      setCurrentPage(result?.data?.pagination?.page)
      setPageSize(result?.data?.pagination?.pageSize)
    } catch (error) {
      addToast('Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleExport = async () => {
    try {
      const queryFilter = {
        dates: filterDates.toString(),
      }
      const queryParams = new URLSearchParams(queryFilter).toString()
      const url = `/interviews/accepted-applicants/fetchAll?${queryParams}&pageNumber=${currentPage}&pageSize=${pageSize}`
      const result = await axiosInstance.get(url)
      const response = result?.data?.data.map((e) => ({
        Recruiter: e.recruiter,
        Applicant: e.applicant_name,
        Role: e.role,
        Client: e.client,
        'Date Accepted': moment(e.date_accepted).format('MM/DD/YYYY'),
      }))
      CSVDownloader.downloadUnparsedCSV(response, 'Accepted_Applicant_List.csv')
    } catch (error) {
      addToast('Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Recruiter',
        accessor: 'recruiter',
      },
      {
        Header: 'Applicant',
        accessor: 'applicant_name',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Client',
        accessor: 'client',
      },
      {
        Header: 'Date Accepted',
        accessor: 'date_accepted',
        Cell: ({ value }) => (value ? moment(value).format('MM/DD/YYYY') : ''),
        sortType: 'datetime',
      },
    ],
    []
  )

  return (
    <StyledAcceptedApplicantList>
      <Flex justify="space-between">
        <DashboardItemTitle title="Accepted Applicant List" />
        <Flex gap="0.5rem">
          <ExportButton onClick={handleExport}>Export</ExportButton>
        </Flex>
      </Flex>

      {loading && <Loader />}

      {tableData?.length > 0 ? (
        <Table
          height="100%"
          totalPage={totalPage}
          currentPage={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          data={tableData}
          columns={tableColumns}
          setNewPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          fetchData={fetchData}
          onClick={() => null}
        ></Table>
      ) : (
        <NoData />
      )}
    </StyledAcceptedApplicantList>
  )
}

export default memo(AcceptedApplicantList)

const StyledAcceptedApplicantList = styled.div`
  width: 100%;
  height: 100%;
`
const ExportButton = styled.button`
  padding: 0.35rem 0.65rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: black;
  color: white;
  border-radius: 100px;
  word-spacing: 3px;
`
