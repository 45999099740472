/* eslint-disable camelcase */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { P } from '../../../../style/Typography.style'

import Flex from '../../../../components/layouts/Flex'
import Tag from '../../../../components/Tag'
import EditSectionHeader from './EditSectionHeader'
import JobDetailsModal from './JobDetailsModal'

import {
  SET_REVIEW_POST_MODAL,
  SHOW_MODAL,
} from '../../../../store/types/modal'

import { BsCheck2 } from 'react-icons/bs'
import SalaryModal from './SalaryModal'
import QualificationModal from './QualificationModal'
import moment from 'moment'

const EditView = () => {
  const dispatch = useDispatch()

  const { reviewPostModal } = useSelector((state) => state.modal)
  const {
    title,
    active_vacancy,
    time_shift,
    description,
    client,
    requires_coaching,
    priority,
    min_salary,
    max_salary,
    salary_basis,
    experience,
    education,
    hardware_requirement,
    certification,
    shift_availability,
    software,
    technical_skills,
    language,
    personal_skills,
    benefits,
    vacancy_for_pooling,
    availability_to_start,
    pointOfContact,
    countries,
    currencies,
    job_rating_group_name,
    interview_rating_group_name,
  } = useSelector((state) => state.jobPosting)

  return (
    <Flex width="100%" margin="2rem 0 0 0" direction="column">
      {/* Job details section */}
      <EditSectionHeader
        title="Job Details"
        onClick={() => {
          dispatch({ type: SHOW_MODAL })
          dispatch({ type: SET_REVIEW_POST_MODAL, payload: 'job-details' })
        }}
      />

      {/* Job Details */}
      <TwoColumn>
        <P>Job Title</P>
        <P>{title}</P>
      </TwoColumn>
      <TwoColumn>
        <P>Active Vacancies</P>
        <P>{active_vacancy}</P>
      </TwoColumn>
      {vacancy_for_pooling && vacancy_for_pooling !== '' ? (
        <TwoColumn>
          <P>Vacancy for Pooling</P>
          <P>{vacancy_for_pooling}</P>
        </TwoColumn>
      ) : null}
      <TwoColumn>
        <P>Time Shift</P>
        <P>{time_shift}</P>
      </TwoColumn>
      <TwoColumn>
        <P>Job Summary</P>
        <P dangerouslySetInnerHTML={{ __html: description }} />
      </TwoColumn>
      <TwoColumn>
        <P>Client</P>
        <P>{client}</P>
      </TwoColumn>
      <TwoColumn>
        <P>Point of Contact</P>
        <P>{pointOfContact}</P>
      </TwoColumn>
      <TwoColumn>
        <P>Job Rating Group</P>
        <P>{job_rating_group_name}</P>
      </TwoColumn>
      <TwoColumn>
        <P>Interview Rating Group</P>
        <P>{interview_rating_group_name}</P>
      </TwoColumn>
      <TwoColumn>
        <P>Vacancy available on countries</P>
        <Flex width="100%" wrap="wrap" gap="0.75rem">
          {countries?.map((item) => (
            <Tag key={item.value}>{item.value}</Tag>
          ))}
        </Flex>
      </TwoColumn>
      <TwoColumn>
        <P>Coaching</P>
        <P>
          {requires_coaching ? (
            <>
              <BsCheck2 color="#5CCA95" size={25} />
              <>{' Required'}</>
            </>
          ) : (
            <>Not Required</>
          )}
        </P>
      </TwoColumn>
      <TwoColumn>
        <P>Marked as Priority</P>
        <P>
          {priority ? (
            <>
              <BsCheck2 color="#5CCA95" size={25} />
              <>{' Yes'}</>
            </>
          ) : (
            <>No</>
          )}
        </P>
      </TwoColumn>

      {/* Salary and Benefits */}
      <EditSectionHeader
        margin="2rem 0 0 0"
        title="Salary and Benefits"
        onClick={() => {
          dispatch({ type: SHOW_MODAL })
          dispatch({ type: SET_REVIEW_POST_MODAL, payload: 'salary' })
        }}
      />

      <TwoColumn>
        <P>Basis</P>
        <P>{salary_basis}</P>
      </TwoColumn>

      <TwoColumn>
        <P>{salary_basis}</P>
        <P>
          {currencies} {min_salary} - {max_salary}
        </P>
      </TwoColumn>

      <TwoColumn>
        <P>Benefits</P>
        <Flex width="100%" wrap="wrap" gap="0.75rem">
          {benefits.map((benefit) => (
            <Tag key={benefit}>{benefit}</Tag>
          ))}
        </Flex>
      </TwoColumn>

      {/* Qualifications */}
      <EditSectionHeader
        margin="2rem 0 0 0"
        title="Qualifications"
        onClick={() => {
          dispatch({ type: SHOW_MODAL })
          dispatch({ type: SET_REVIEW_POST_MODAL, payload: 'qualifications' })
        }}
      />

      <TwoColumn>
        <P>Experience</P>
        <Flex direction="column" gap="0.5rem">
          <P>
            {experience[0].specification} : {experience[0].years}
          </P>
          {experience[1].specification !== '' && (
            <P>
              {experience[1].specification} : {experience[1].years}
            </P>
          )}
        </Flex>
      </TwoColumn>

      <TwoColumn>
        <P>Required Technical Skills</P>
        <Flex width="100%" wrap="wrap" gap="0.75rem">
          {technical_skills.map((item) => (
            <Tag key={item.value}>{item.value}</Tag>
          ))}
        </Flex>
      </TwoColumn>

      {availability_to_start && (
        <TwoColumn>
          <P>Availability to Start</P>
          <P>{moment(availability_to_start).format('MMM Do YY')}</P>
        </TwoColumn>
      )}

      {education && education !== '' ? (
        <TwoColumn>
          <P>Education</P>
          <P>{education}</P>
        </TwoColumn>
      ) : null}

      {hardware_requirement && hardware_requirement.length > 0 ? (
        <TwoColumn>
          <P>Hardware Requirements</P>
          <div>
            {hardware_requirement.map((item) => (
              <P key={item}>{item}</P>
            ))}
          </div>
        </TwoColumn>
      ) : null}

      {language && language.length > 0 ? (
        <TwoColumn>
          <P>Language</P>
          <Flex direction="column" gap="0.5rem">
            {language.map((item) => (
              <P key={item.value}>{item.value}</P>
            ))}
          </Flex>
        </TwoColumn>
      ) : null}

      {certification && certification !== '' ? (
        <TwoColumn>
          <P>License/Certification</P>
          <P>{certification}</P>
        </TwoColumn>
      ) : null}

      {personal_skills && personal_skills.length > 0 ? (
        <TwoColumn>
          <P>Personal Skills</P>
          <Flex width="100%" wrap="wrap" gap="0.75rem">
            {personal_skills.map((item) => (
              <Tag key={item.value}>{item.value}</Tag>
            ))}
          </Flex>
        </TwoColumn>
      ) : null}

      {shift_availability && shift_availability !== '' ? (
        <TwoColumn>
          <P>Shift Preference</P>
          <P>{shift_availability}</P>
        </TwoColumn>
      ) : null}

      {software && software !== '' ? (
        <TwoColumn>
          <P>Software</P>
          <Flex width="100%" wrap="wrap" gap="0.75rem">
            {software.map((item) => (
              <Tag key={item.value}>{item.value}</Tag>
            ))}
          </Flex>
        </TwoColumn>
      ) : null}

      {/* modals */}
      {reviewPostModal === 'job-details' && <JobDetailsModal />}
      {reviewPostModal === 'salary' && <SalaryModal />}
      {reviewPostModal === 'qualifications' && <QualificationModal />}
    </Flex>
  )
}

export default EditView

const TwoColumn = styled.div`
  width: 100%;
  padding: 1.5rem 0 0.7rem 0;
  display: grid;
  grid-template-columns: 180px 1fr;
  align-items: flex-start;
  border-bottom: 1px solid #dfdfe9;
`
