import React, { useState, useEffect, useMemo } from 'react'

import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Joi from 'joi'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import DashboardInput from '../../../../components/dashboard/DashboardInput'

import {
  insertRatingQuestion,
  updateRatingQuestion,
  setErrorMessage,
} from '../../../../store/actions/ratingActions'

const SaveRatingQuestion = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { loading, modal, selectedRecord } = useSelector(
    (state) => state.ratings
  )

  const validationSchema = useMemo(() => {
    return Joi.object({
      question: Joi.string().required().min(1).messages({
        'string.empty': 'Question is required',
        'any.required': 'Question is required',
      }),
      rating_group_id: Joi.number().required(),
    })
  }, [])

  const [question, setQuestion] = useState('')
  const [fieldErrors, setFieldErrors] = useState([])

  const handleSaveRatingQuestion = () => {
    const payload = {
      question,
      rating_group_id: id,
    }

    const result = validationSchema.validate(payload, {
      abortEarly: false,
    })

    if (result.error) {
      const { error } = result
      const fieldNames = Object.keys(payload)
      const updatedErrors = fieldNames.reduce((acc, fieldName) => {
        acc[fieldName] =
          error?.details
            .find((detail) => detail.context.key === fieldName)
            ?.message.replace(/"/g, '') || ''
        return acc
      }, {})

      setFieldErrors(updatedErrors)

      dispatch(
        setErrorMessage(
          'Please correct the validation errors first before proceeding.'
        )
      )
      return
    }

    if (modal === 'updateRatingQuestion') {
      dispatch(updateRatingQuestion(selectedRecord.ratingQuestion.id, payload))
    } else {
      dispatch(insertRatingQuestion(payload))
    }
  }

  useEffect(() => {
    if (modal === 'updateRatingQuestion') {
      setQuestion(selectedRecord?.ratingQuestion.question)
    }
  }, [selectedRecord.ratingQuestion, modal])

  return (
    <MainModal
      title={
        modal === 'updateRatingQuestion'
          ? 'Update Rating Question'
          : 'Create Rating Question'
      }
      onClick={handleSaveRatingQuestion}
      buttonText="Submit"
      loading={loading}
    >
      <Flex gap="0.4rem">
        <DashboardInput
          errorMessage={fieldErrors.question}
          name="question"
          label="Question *"
          placeholder="Enter Question"
          type="text"
          height="2.5rem"
          value={question}
          handleChange={(e) => setQuestion(e.target.value)}
        />
      </Flex>
    </MainModal>
  )
}

export default SaveRatingQuestion
