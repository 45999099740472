// job posting
export const SWITCH_STEP = 'SWITCH_STEP'
export const SET_REACH_STEP = 'SET_REACH_STEP'
export const SET_POLLING = 'SET_POLLING'

// to add more fields to qualification
export const SET_IS_EDUCATION = 'SET_IS_EDUCATION'
export const SET_IS_LANGUAGE = 'SET_IS_LANGUAGE'
export const SET_IS_SHIFT = 'SET_IS_SHIFT'
export const SET_IS_EXPERIENCE = 'SET_IS_EXPERIENCE'
export const SET_IS_CERTIFICATION = 'SET_IS_CERTIFICATION'
export const SET_IS_SOFTWARE = 'SET_IS_SOFTWARE'
export const SET_IS_HARDWARE = 'SET_IS_HARDWARE'
export const SET_IS_SKILL = 'SET_IS_SKILL'
export const SET_IS_PERSONAL_SKILLS = 'SET_IS_PERSONAL_SKILLS'

// to update input value
export const SET_JOB_TITLE = 'SET_JOB_TITLE'
export const SET_JOB_DESCRIPTION = 'SET_JOB_DESCRIPTION'
export const SET_JOB_TYPE = 'SET_JOB_TYPE'
export const SET_EDUCATION = 'SET_EDUCATION'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_EXPERIENCE = 'SET_EXPERIENCE'
export const SET_CLOSING_DATE = 'SET_CLOSING_DATE'
export const SET_TIME_SHIFT = 'SET_TIME_SHIFT'
export const SET_ACTIVE_VACANCY = 'SET_ACTIVE_VACANCY'
export const SET_VACANCY_FOR_POOLING = 'SET_VACANCY_FOR_POOLING'
export const SET_REQUIRES_COACHING = 'SET_REQUIRES_COACHING'
export const SET_PRIORITY = 'SET_PRIORITY'
export const SET_CERTIFICATION = 'SET_CERTIFICATION'
export const SET_TECHNICAL_SKILLS = 'SET_TECHNICAL_SKILLS'
export const SET_SOFTWARE = 'SET_SOFTWARE'
export const SET_HARDWARE_REQUIREMENT = 'SET_HARDWARE_REQUIREMENT'
export const SET_SHIFT_AVAILABILITY = 'SET_SHIFT_AVAILABILITY'
export const SET_COMPANY_ID = 'SET_COMPANY_ID'
export const SET_POC_ID = 'SET_POC_ID'
export const SET_PERSONAL_SKILLS = 'SET_PERSONAL_SKILLS'
export const SET_MAX_SALARY = 'SET_MAX_SALARY'
export const SET_MIN_SALARY = 'SET_MIN_SALARY'
export const SET_BENEFITS = 'SET_BENEFITS'
export const SET_SALARY_BASIS = 'SET_SALARY_BASIS'
export const CLEAR_JOB_POSTING = 'CLEAR_JOB_POSTING'
export const SET_IS_AVAILABLE_TO_START = 'SET_IS_AVAILABLE_TO_START'
export const SET_AVAILABLE_TO_START_DATE = 'SET_AVAILABLE_TO_START_DATE'
export const SET_JOB_RATING_GROUP = 'SET_JOB_RATING_GROUP'
export const SET_INTERVIEW_RATING_GROUP = 'SET_INTERVIEW_RATING_GROUP'

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const SET_RECRUITER_ID = 'SET_RECRUITER_ID'

export const SET_UPDATE_STATUS_TAB = 'SET_UPDATE_STATUS_TAB'
export const SET_ACTIVE_JOB_ID = 'SET_ACTIVE_JOB_ID'

export const SET_AVAILABLE_COUNTRIES = 'SET_AVAILABLE_COUNTRIES'
export const SET_CURRENCY = 'SET_CURRENCY'
