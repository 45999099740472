import {
  SET_DOWNLOAD_MODAL,
  SET_CV_PHOTO,
  SET_WFH,
  SET_REMOTE_WORK_ETHICS,
  ADD_TALENT,
  SET_SHOW_LAST_NAME,
  SET_SHOW_CONTACT_DETAILS,
  SET_SHOW_CHAR_REF,
  SET_SHOW_CONFIRM_LEAVE_MODAL,
  SET_SPEED_TEST_TOGGLE,
} from './types'

import {
  SET_SHARE_JOB_POST_MODAL,
  SET_EDIT_APPLICANT_MODAL,
} from '../types/modal'

// set download modal
export const downloadModal = () => (dispatch, getState) => {
  const { downloadModal } = getState().modal

  dispatch({
    type: SET_DOWNLOAD_MODAL,
    payload: !downloadModal,
  })
}

// set cv photo
export const cvPhotoToggle = () => (dispatch, getState) => {
  const { cvPhoto } = getState().modal

  dispatch({
    type: SET_CV_PHOTO,
    payload: !cvPhoto,
  })
}

// set wfh toggle
export const wfhToggle = () => (dispatch, getState) => {
  const { wfh } = getState().modal

  dispatch({
    type: SET_WFH,
    payload: !wfh,
  })
}

// toggle remote work ethics
export const remoteWorkEthicsToggle = () => (dispatch, getState) => {
  const { remoteWorkEthics } = getState().modal

  dispatch({
    type: SET_REMOTE_WORK_ETHICS,
    payload: !remoteWorkEthics,
  })
}

// toggle last name
export const lastNameToggle = () => (dispatch, getState) => {
  const { showLastName } = getState().modal

  dispatch({
    type: SET_SHOW_LAST_NAME,
    payload: !showLastName,
  })
}

// toggle contact details
export const contactDetailsToggle = () => (dispatch, getState) => {
  const { contactDetails } = getState().modal

  dispatch({
    type: SET_SHOW_CONTACT_DETAILS,
    payload: !contactDetails,
  })
}

// toggle character references
export const charRefToggle = () => (dispatch, getState) => {
  const { charRef } = getState().modal

  dispatch({
    type: SET_SHOW_CHAR_REF,
    payload: !charRef,
  })
}

export const speedTestToggle = () => (dispatch, getState) => {
  const { showSpeedTest } = getState().modal

  dispatch({
    type: SET_SPEED_TEST_TOGGLE,
    payload: !showSpeedTest,
  })
}

// show lists
export function showLists({ showList }) {
  return {
    type: ADD_TALENT,
    payload: showList || true,
  }
}

// set share job post modal
export function setShareJobPostModal(payload) {
  return {
    type: SET_SHARE_JOB_POST_MODAL,
    payload: payload,
  }
}

export function setEditApplicantModal(payload) {
  return {
    type: SET_EDIT_APPLICANT_MODAL,
    payload: payload,
  }
}

export function showConfirmLeaveModal(payload) {
  return {
    type: SET_SHOW_CONFIRM_LEAVE_MODAL,
    payload: payload,
  }
}
