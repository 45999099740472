import moment from 'moment'

import axiosInstance from '../../services/config/AxiosIntance'

import ratings from '../types/ratings'

export const setModal = (modalType) => {
  return { type: ratings.SET_MODAL, payload: modalType }
}
export const setErrorMessage = (message) => {
  return { type: ratings.SET_ERROR, payload: message }
}
export const setPagination = (options) => {
  return { type: ratings.SET_PAGINATION_OPTIONS, payload: options }
}
export const setQueryParams = (params) => {
  return { type: ratings.SET_QUERY_PARAMS, payload: params }
}
export const setSelectedRecord = (payload, key = 'ratingGroup') => {
  return { type: ratings.SET_SELECTED_RECORD, payload: payload, key: key }
}

export const resetNotification = () => {
  return { type: ratings.RESET_NOTIFICATION }
}

const ratingGroupUrl = '/rating-group'
export const insertRatingGroup =
  (formData, customFields) => async (dispatch) => {
    dispatch({ type: ratings.SAVE_RATING_GROUP_DATA_LOADING })
    try {
      const result = await axiosInstance.post(
        `${ratingGroupUrl}/create`,
        formData
      )

      const data = result.data.data

      const successMessage = result.data.message
      dispatch({
        type: ratings.ADD_RATING_GROUP_DATA_SUCCESS,
        payload: {
          data: { ...data, ...customFields },
          message: successMessage,
        },
      })
    } catch (error) {
      dispatch({ type: ratings.SAVE_RATING_GROUP_DATA_ERROR, payload: error })
    }
  }

const ratingQuestionUrl = '/rating-question'
export const insertRatingQuestion =
  (formData, customFields) => async (dispatch) => {
    dispatch({ type: ratings.SAVE_RATING_QUESTION_DATA_LOADING })
    try {
      const result = await axiosInstance.post(
        `${ratingQuestionUrl}/create`,
        formData
      )

      const data = result.data.data

      const successMessage = result.data.message
      dispatch({
        type: ratings.ADD_RATING_QUESTION_DATA_SUCCESS,
        payload: {
          data: { ...data, ...customFields },
          message: successMessage,
        },
      })
    } catch (error) {
      dispatch({
        type: ratings.SAVE_RATING_QUESTION_DATA_ERROR,
        payload: error,
      })
    }
  }

export const updateRatingGroup =
  (id, formData, customFields) => async (dispatch, getState) => {
    const selectedRecord = getState().ratings.selectedRecord
    dispatch({ type: ratings.SAVE_RATING_GROUP_DATA_LOADING })
    try {
      const result = await axiosInstance.put(
        `${ratingGroupUrl}/${id}/update`,
        formData
      )
      const data = formData
      data.id = id
      data.created_at = selectedRecord.created_at
      data.updated_at = moment()
      const successMessage = result.data.message
      dispatch({
        type: ratings.UPDATE_RATING_GROUP_DATA_SUCCESS,
        payload: {
          data: { ...data, ...customFields },
          id: id,
          message: successMessage,
        },
      })
    } catch (error) {
      dispatch({ type: ratings.SAVE_RATING_GROUP_DATA_ERROR, payload: error })
    }
  }
export const updateRatingQuestion =
  (id, formData, customFields) => async (dispatch, getState) => {
    const selectedRecord = getState().ratings.selectedRecord
    dispatch({ type: ratings.SAVE_RATING_QUESTION_DATA_LOADING })
    try {
      const result = await axiosInstance.put(
        `${ratingQuestionUrl}/${id}/update`,
        formData
      )
      const data = formData
      data.id = id
      data.created_at = selectedRecord.created_at
      data.updated_at = moment()
      const successMessage = result.data.message
      dispatch({
        type: ratings.UPDATE_RATING_QUESTION_DATA_SUCCESS,
        payload: {
          data: { ...data, ...customFields },
          id: id,
          message: successMessage,
        },
      })
    } catch (error) {
      dispatch({
        type: ratings.SAVE_RATING_QUESTION_DATA_ERROR,
        payload: error,
      })
    }
  }

export const fetchRatingGroupList = (url) => async (dispatch) => {
  dispatch({ type: ratings.FETCH_RATING_GROUP_LIST_LOADING })
  try {
    const { data } = await axiosInstance.get(url)
    let result
    if (data?.resp) {
      result = data.resp
    } else {
      result = []
    }
    dispatch({
      type: ratings.FETCH_RATING_GROUP_LIST_SUCCESS,
      payload: result,
    })
  } catch (error) {
    dispatch({ type: ratings.FETCH_RATING_GROUP_LIST_ERROR, payload: error })
  }
}
export const fetchRatingQuestionList = (url) => async (dispatch) => {
  dispatch({ type: ratings.FETCH_RATING_QUESTION_LIST_LOADING })
  try {
    const { data } = await axiosInstance.get(url)
    let result
    if (data?.resp) {
      result = data.resp
    } else {
      result = []
    }
    dispatch({
      type: ratings.FETCH_RATING_QUESTION_LIST_SUCCESS,
      payload: result,
    })
  } catch (error) {
    dispatch({ type: ratings.FETCH_RATING_QUESTION_LIST_ERROR, payload: error })
  }
}

export const fetchRatingGroupRecord =
  (url = ratingGroupUrl, customFields) =>
  async (dispatch) => {
    dispatch({ type: ratings.FETCH_RATING_GROUP_RECORD_LOADING })
    try {
      const response = await axiosInstance.get(url)
      const result = response?.data?.resp[0]

      dispatch({
        type: ratings.FETCH_RATING_GROUP_RECORD_SUCCESS,
        payload: { ...result, ...customFields },
      })
    } catch (error) {
      dispatch({
        type: ratings.FETCH_RATING_GROUP_RECORD_ERROR,
        payload: error,
      })
    }
  }

export const fetchRatingQuestionRecord =
  (url = ratingQuestionUrl, customFields) =>
  async (dispatch) => {
    dispatch({ type: ratings.FETCH_RATING_QUESTION_RECORD_LOADING })
    try {
      const response = await axiosInstance.get(url)
      const result = response?.data?.resp[0]

      dispatch({
        type: ratings.FETCH_RATING_QUESTION_RECORD_SUCCESS,
        payload: { ...result, ...customFields },
      })
    } catch (error) {
      dispatch({
        type: ratings.FETCH_RATING_QUESTION_RECORD_ERROR,
        payload: error,
      })
    }
  }
