import React, { useState } from 'react'

import MainModal from '../../../modal/MainModal'
import Flex from '../../../../layouts/Flex'

import RichTextbox from '../../../controls/RichTextBox'

const UpdateQuestionNotes = ({
  answeredQuestions,
  setAnsweredQuestions,
  currentQuestion,
  setNoteModal,
}) => {
  const [notes, setNotes] = useState(currentQuestion[0]?.notes || '<p><br></p>')

  const handleChangeNotes = (e) => {
    setNotes(e)
  }

  const handleAddNotes = () => {
    const updatedQuestion = { ...currentQuestion[0], notes }
    const updatedQuestions = answeredQuestions.map((q) => {
      if (
        parseInt(q.question_id) === parseInt(currentQuestion[0]?.question_id)
      ) {
        return updatedQuestion
      }
      return q
    })
    setAnsweredQuestions(updatedQuestions)
    handleClose()
  }

  const handleClose = () => {
    // close notes modal without closing the parent modal
    return setNoteModal(false)
  }

  return (
    <MainModal
      title="Update Question Notes"
      onClick={handleAddNotes}
      buttonText="Submit"
      onClose={handleClose}
    >
      <Flex gap="0.4rem">
        <RichTextbox content={notes} setContent={handleChangeNotes} />
      </Flex>
    </MainModal>
  )
}

export default UpdateQuestionNotes
