import React from 'react'

import styled from 'styled-components'
import FilterInput from '../../../../../src/components/dashboard/FilterInput'
import DashboardButton from '../../../../../src/components/dashboard/DashboardButton'
import { FiPlus } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import {
  setSearchTermKeyword,
  filterJobList,
} from '../../../../store/actions/jobListingActions'

const SearchTermTopFilter = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    searchTermKeyword,
    jobTitle,
    jobTitlekeyword,
    jobRole,
    location,
    industry,
    experienceLevel,
    jobType,
    salaryRange,
    minSalaryRange,
    maxSalaryRange,
    requiredSkills,
    requiredSkillsSoftware,
    certifications,
    postedDate,
    minDateRange,
    maxDateRange,
    benefits,
    educationReq,
  } = useSelector((state) => state.jobListing)

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant

  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  const handleSearch = (e) => {
    e.preventDefault()

    let salaryRangeValue = null
    let postedby = null
    let company = null

    if (role === 'recruiter' && !recruiterAssistant) {
      postedby = userId
    } else if (role === 'poc') {
      company = JSON.stringify(companyDetails)
    }

    if (salaryRange !== null) {
      salaryRangeValue = minSalaryRange + ',' + maxSalaryRange //*
    }

    const jobTitleValues =
      jobTitle || jobTitlekeyword || jobRole
        ? {
            job_Title: jobTitle,
            job_Titlekeyword: jobTitlekeyword,
            job_Role: jobRole,
          }
        : null

    const reqSkillsAndCerts =
      requiredSkills || requiredSkillsSoftware || certifications
        ? {
            reqSkill: requiredSkills,
            reqSoftware: requiredSkillsSoftware,
            certification: certifications,
          }
        : null

    const params = {
      searchJobBy:
        searchTermKeyword && searchTermKeyword !== null
          ? searchTermKeyword
          : undefined,
      salary_basis:
        salaryRangeValue && salaryRangeValue !== null
          ? salaryRangeValue
          : undefined,
      date_posted: postedDate && postedDate !== null ? postedDate : undefined,
      minDateRange:
        postedDate && postedDate !== null ? minDateRange : undefined,
      maxDateRange:
        postedDate && postedDate !== null ? maxDateRange : undefined,
      country: location && location !== null ? location : undefined,
      job_type: jobType && jobType !== null ? jobType : undefined,
      job_terms:
        jobTitleValues && jobTitleValues !== null ? jobTitleValues : undefined,
      industry: industry && industry !== null ? industry : undefined,
      skills_terms:
        reqSkillsAndCerts && reqSkillsAndCerts !== null
          ? reqSkillsAndCerts
          : undefined,
      benefits: benefits && benefits !== null ? benefits : undefined,
      educationReq:
        educationReq && educationReq !== null ? educationReq : undefined,
      experienceLevel:
        experienceLevel && experienceLevel !== null
          ? experienceLevel
          : undefined,
      posted_by: postedby && postedby !== null ? postedby : undefined,
      companyDetails: company && company !== null ? company : undefined,
      page: 1,
    }

    dispatch(setSearchTermKeyword(searchTermKeyword))
    dispatch(filterJobList(params))
  }

  const handleClientModal = () => {
    navigate('/job-posting/create')
  }

  return (
    <StyledTopFilter>
      <div className="filterWrapper">
        <form onSubmit={(e) => handleSearch(e)} className="inputFilter">
          <FilterInput
            maxW="600px"
            onChange={(e) => {
              dispatch(setSearchTermKeyword(e.target.value))
            }}
            value={searchTermKeyword}
            placeholder="Search Job title, Skills, Company, Location..."
          />

          <DashboardButton type="submit" marginRight="10px" />

          <DashboardButton
            text="Create new job post"
            type="button"
            notIcon
            externalIcon={<FiPlus className="icon" />}
            maxWidth="180px"
            onClick={handleClientModal}
          />
        </form>
      </div>
    </StyledTopFilter>
  )
}

export default SearchTermTopFilter

const StyledTopFilter = styled.div`
  position: sticky;
  top: 4.2rem;
  z-index: 4;
  width: 100%;
  margin-top: 1.5rem;

  .filterWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9f9fc;
    padding: 0.25rem 0;
  }

  .inputFilter {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .checkedFilter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9fc;
    max-width: 600px;
    padding: 0.75rem 0;
  }
`
