import {
  SET_DOWNLOAD_MODAL,
  SET_CV_PHOTO,
  SET_WFH,
  SET_REMOTE_WORK_ETHICS,
  SET_SHOW_LAST_NAME,
  SET_SHOW_CONTACT_DETAILS,
  OPEN_ACTIVITIES_MODAL,
  CLOSE_ACTIVITIES_MODAL,
  SET_SHOW_CHAR_REF,
  SET_ICON_MODAL,
  ADD_TALENT,
  SET_SHOW_CONFIRM_LEAVE_MODAL,
  SET_SPEED_TEST_TOGGLE,
} from '../actions/types'

import {
  SHOW_MODAL,
  HIDE_MODAL,
  SET_SHARE_MODAL_STEP,
  SET_REVIEW_POST_MODAL,
  SET_SHARE_JOB_POST_MODAL,
  SET_EDIT_APPLICANT_MODAL,
} from '../types/modal'

const initialState = {
  downloadModal: false,
  cvData: true,
  contactDetails: true,
  cvPhoto: false,
  wfh: true,
  remoteWorkEthics: true,
  showLastName: true,
  charRef: true,
  activities: '',
  iconModal: '',
  showList: false,
  showModal: true,
  shareStep: 0,
  shareJobPostModal: false,
  reviewPostModal: '',
  editApplicantModal: false,
  showConfirmLeaveModal: false,
  showSpeedTest: true,
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        showModal: true,
      }

    case HIDE_MODAL:
      return {
        ...state,
        showModal: false,
      }

    case SET_SHARE_MODAL_STEP:
      return {
        ...state,
        shareStep: action.payload,
      }

    case SET_REVIEW_POST_MODAL:
      return {
        ...state,
        reviewPostModal: action.payload,
      }

    case SET_DOWNLOAD_MODAL:
      return {
        ...state,
        downloadModal: action.payload,
      }

    case SET_CV_PHOTO:
      return {
        ...state,
        cvPhoto: action.payload,
      }

    case SET_WFH:
      return {
        ...state,
        wfh: action.payload,
      }

    case SET_REMOTE_WORK_ETHICS:
      return {
        ...state,
        remoteWorkEthics: action.payload,
      }

    case SET_SHOW_LAST_NAME:
      return {
        ...state,
        showLastName: action.payload,
      }
    case SET_SHOW_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: action.payload,
      }
    case SET_SHOW_CHAR_REF:
      return {
        ...state,
        charRef: action.payload,
      }
    case OPEN_ACTIVITIES_MODAL:
      return {
        ...state,
        activities: action.payload,
      }

    case CLOSE_ACTIVITIES_MODAL:
      return {
        ...state,
        activities: '',
      }

    case SET_ICON_MODAL:
      return {
        ...state,
        iconModal: action.payload,
      }

    case ADD_TALENT:
      return {
        ...state,
        showList: action.payload,
      }

    case SET_SHARE_JOB_POST_MODAL:
      return {
        ...state,
        shareJobPostModal: action.payload,
      }
    case SET_EDIT_APPLICANT_MODAL:
      return {
        ...state,
        editApplicantModal: action.payload,
      }
    case SET_SHOW_CONFIRM_LEAVE_MODAL:
      return {
        ...state,
        showConfirmLeaveModal: action.payload,
      }
    case SET_SPEED_TEST_TOGGLE:
      return {
        ...state,
        showSpeedTest: action.payload,
      }
    default:
      return state
  }
}

export default modalReducer
